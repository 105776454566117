<template>
  <div class="checkout-order-details">
    <section>
      <div class="container is-fullhd">
        <h3 class="title is-3 has-text-centered pb-6">Tramitar pedido</h3>
        <div class="checkout-wrap">
          <tab-wrap></tab-wrap>
          <div class="columns content-checkout-wrap is-flex-wrap-wrap">
            <div class="column is-12 ">
              <h4 class="title is-size-4 txt-c-mobile">Complete your order details</h4>
              <p class="txt-c-mobile">
                Founded in Madrid, STL(AB) strives to provide the best service for dental professionals.
              </p>
            </div>
            <div class="column is-12 table-header">
              <div class="columns">
                <div class="column is-3">
                  <h4 class="service-category-title txt-14 txt-yellow">
                    LABORATORIO
                  </h4>
                </div>
                <div class="column is-9">
                  <div class="columns">
                    <div class="column is-5">
                      <h4 class="service-category-title txt-14 txt-yellow">
                        PRODUCTO
                      </h4>
                    </div>
                    <div class="column is-4">
                      <h4 class="service-category-title txt-14 txt-yellow">
                        TIPO
                      </h4>
                    </div>
                    <div class="column is-1">
                      <h4 class="service-category-title txt-14 txt-yellow has-text-center">
                        QUANTITY
                      </h4>
                    </div>
                    <div class="column is-2">
                      <h4 class="service-category-title txt-14 txt-yellow has-text-right">
                        PRECIO UNITARIO
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <template v-for="lab in productsBylab">
              <div class="column is-12 lab-detail-wrap mb-6" v-bind:key="lab.id">
                <div class="columns">
                  <div class="column is-3">
                    <h4 class="service-category-title show-mobile txt-16 txt-yellow has-text-weight-bold">
                      LABORATORIO
                    </h4>
                    <p class="has-text-weight-bold txt-16" v-html="lab.name"></p>
                    <p v-html="lab.address_city"></p>
                  </div>
                  <div class="column is-9">
                    <template v-for="product in lab.products">
                      <cart-product :cart-product="product" :update-product-cb="updateProductCb" v-bind:key="product.id" :extras="lab.extras"></cart-product>
                    </template>
                    <div class="columns mb-5" v-if="lab.hasFreeShipping">
                      <div class="column">
                        <p class="txt-14 txt-yellow">
                          Añade otro producto por 21€ y consigue el envío gratuito de este laboratorio. <a class="is-underlined" href="#" >Sigue con otra orden</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <div class="column is-6 ">
              <h4 class="title is-size-4">¿Necesitas ayuda?</h4>
              <p>
                Cras mattis consectetur purus sit amet fermentum. Etiam porta sem malesuada magna mollis euismod. Donec sed odio dui. Curabitur blandit tempus porttitor.
              </p>
              <ul class="mt-3">
                <li>
                  <a class="is-underlined" href="#">Contactar con nosotros</a>
                </li>
                <li>
                  <a class="is-underlined" href="#">Ver la sección de ayuda</a>
                </li>
                <li>
                  <a class="is-underlined" href="#">Política de envíos y devoluciones</a>
                </li>
              </ul>
            </div>
            <div class="column is-6 recap-wrap">
              <div class="recap-row">
                <div class="recap-label">
                  <p><strong class="txt-yellow">SUBTOTAL</strong></p>
                </div>
                <div class="recap-value">
                  <p class="txt-18">
                    {{shoppingCart.subtotal_price / 100}}€
                  </p>
                </div>
              </div>

              <div class="recap-row">
                <div class="recap-label">
                  <p><strong class="txt-yellow">Coste de envío estimado</strong></p>
                </div>
                <div class="recap-value">
                  <p class="txt-18">
                    {{shoppingCart.total_shipping_price / 100}}€
                  </p>
                </div>
              </div>
              <template v-for="shippingLine in shoppingCart.shipping_lines">
                <div class="recap-row half-h" v-bind:key="shippingLine.laboratory.id">
                  <div class="recap-label">
                    <p class="txt-12">{{shippingLine.laboratory.name}}</p>
                  </div>
                  <div class="recap-value">
                    <span class="txt-12" v-if="shippingLine.price === 0">Gratis</span>
                    <span class="txt-12" v-if="shippingLine.price > 0">{{shippingLine.price / 100}}€</span>
                  </div>
                </div>
              </template>
              <div class="recap-row mt-20">
                <div class="recap-label">
                  <p><strong class="txt-yellow">Código promocional</strong></p>
                </div>
              </div>
              <div class="recap-row">
                <input class="input is-primary" type="text" placeholder="Código" v-model="promotionalCode">
                <button class="button is-primary ml-4" :disabled="promotionalCode === ''">Aplicar</button>
              </div>
              <hr class="separator">
              <div class="recap-row mt-20">
                <div class="recap-label">
                  <p><strong class="txt-yellow">TOTAL</strong></p>
                </div>
                <div class="recap-value">
                  <p class="txt-18">
                    {{shoppingCart.total_price / 100}}€
                  </p>
                </div>
              </div>

              <div class="recap-row no-h">
                <button class="button is-primary is-fullwidth is-medium mt-4" @click="goTo('/checkout/shipping')">Tramitar el pedido</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import TabWrap from '@/components/checkout/TabWrap.vue'
import CartProduct from '@/components/checkout/CartProduct.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'OrderDetails',
  components: {
    Footer,
    TabWrap,
    'cart-product': CartProduct
  },
  data () {
    return {
      promotionalCode: '',
      productsBylab: []
    }
  },
  computed: {
    ...mapGetters({
      clinic: 'user/clinic',
      shoppingCart: 'clinic/shoppingCart'
    })
  },
  methods: {
    openEditAttachment (attachment) {},
    goTo (path) {
      this.$router.push({ path: path })
    },
    updateProductCb () {
      this.fetch()
    },
    initProductsList () {
      const self = this
      let i = 0
      for (i = 0; i < this.shoppingCart.items.length; i++) {
        if (!this.productsBylab.find(e => e.id === self.shoppingCart.items[i].laboratory.id)) {
          this.productsBylab.push({
            id: this.shoppingCart.items[i].laboratory.id,
            address_city: this.shoppingCart.items[i].laboratory.address_city,
            address_state: this.shoppingCart.items[i].laboratory.address_state,
            name: this.shoppingCart.items[i].laboratory.name,
            products: [],
            extras: []
          })
        }
      }

      for (i = 0; i < this.shoppingCart.items.length; i++) {
        for (let j = 0; j < this.productsBylab.length; j++) {
          if (this.shoppingCart.items[i].laboratory.id === this.productsBylab[j].id) {
            if (this.shoppingCart.items[i].extra) {
              this.productsBylab[j].extras.push(this.shoppingCart.items[i])
            } else {
              this.productsBylab[j].products.push(this.shoppingCart.items[i])
            }
          }
        }
      }
    },
    fetch () {
      const self = this
      this.productsBylab = []
      this.$store.dispatch('clinic/getShoppingCart', {
        clinicId: this.clinic.id,
        cb: (response) => {
          self.initProductsList()
        }
      })
    }
  },
  created () {
    this.fetch()
  }
}
</script>

<style scoped lang="scss">
.checkout-order-details{
  width: 100%;
  section{
    width: 100%;
    padding: 60px 0px;
    .checkout-wrap{
      width: 100%;
      border-radius: 4px;
      background-color: #FFFFFF;
      box-shadow: 0 2px 24px 0 rgba(0,0,0,0.2);
      .content-checkout-wrap{
        padding: 60px;
        .lab-detail-wrap{
          border-bottom: 1px solid #57567C;
        }
        .recap-wrap{
          .recap-row{
            overflow: hidden;
            height: 40px;
            width: 100%;
            display: flex;
            &.half-h{
              height: 20px;
            }
            &.no-h{
              height: auto;
            }
            &.mt-20{
              margin-top: 20px;
            }
            .recap-label{
              text-transform: uppercase;
              width: 85%;
            }
            .recap-value{
              text-align: right;
              width: 15%;
            }
            input{
              border-width: 1px;
            }
          }
          .separator{
            margin-top: 40px;
            margin-bottom: 30px;
            background-color: #57567C;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .checkout-order-details{
    section{
      .checkout-wrap{
        .content-checkout-wrap{
          padding: 35px;
          .table-header{
            display: none;
          }
          .login-wrap{
            border-right: 0px;
            overflow: hidden;
            form{
              button{
                display: block;
                line-height: 0px;
              }
            }
          }
          .register-wrap{
            overflow: hidden;
            button{
              position: initial;
              transform: initial;
              max-width: initial;
            }
          }
        }
      }
    }
  }
}
</style>
