<template>
  <div class="cart-item-wrap mb-5">
    <div class="columns" v-if="cartProduct !== null">
      <div class="column is-5">
        <h4 class="service-category-title show-mobile txt-16 txt-yellow has-text-weight-bold">
          PRODUCTO
        </h4>
        <h4 class="service-category-title has-text-weight-bold txt-18">
          {{cartProduct.product_name}}
        </h4>
      </div>
      <div class="column is-4">
        <h4 class="service-category-title show-mobile txt-16 txt-yellow has-text-weight-bold">
          TIPO
        </h4>
        <template v-for="attachment in cartProduct.attachments">
          <p v-if="cartProduct.type.hasAttachment" class="txt-14" v-bind:key="attachment.id">
            Archivio {{attachment.fileName}} - ({{attachment.fileSize}}) - <a class="is-underlined" href="#" @click="openEditAttachment(attachment)">Editar</a><br>
            Paciente: {{cartProduct.patientName}} {{cartProduct.patientLastName}}
          </p>
        </template>
        <p class="txt-14">
          Paciente: {{cartProduct.first_name}} {{cartProduct.last_name}}
        </p>
      </div>
      <div class="column is-1">
        <h4 class="service-category-title show-mobile txt-16 txt-yellow has-text-weight-bold">
          QUANTITY
        </h4>
        <h4 class="service-category-title has-text-center">
          {{cartProduct.quantity}}
        </h4>
      </div>
      <div class="column is-2">
        <h4 class="service-category-title show-mobile txt-16 txt-yellow has-text-weight-bold">
          UNIT PRECIO
        </h4>
        <h4 class="service-category-title has-text-right">
          {{cartProduct.unit_price / 100}} €
        </h4>
      </div>
    </div>
    <div class="columns mobile-action-wrap" v-if="cartProduct !== null">
      <div class="column is-5">
          <div class="notification has-background-light txt-14" v-if="cartProduct.notes !== ''">
            {{cartProduct.notes}}
          </div>
      </div>
      <div class="column is-5 mobile-edit">
        <button class="button is-primary is-outline" @click="editProduct(cartProduct)">
          EDITAR
        </button>
      </div>
      <div class="column is-2 mobile-remove has-text-right">
        <a class="is-underlined" href="#" @click="removeProduct()">Eliminar</a> <span class="icon-remove"></span>
      </div>
    </div>
    <div class="columns" v-if="extras.length > 0">
      <complementary-list :product-offer-id="cartProduct.product_offer_id" :active-extras="extras" :show-extras="extras.length > 0"></complementary-list>
    </div>
  </div>
</template>

<script>
import {
  IsEmptyOrNull
} from '@/utils/utils.js'
import ComplementaryList from '@/components/ComplementaryList.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'CartProduct',
  props: {
    cartProduct: {
      type: Object,
      default: null
    },
    updateProductCb: Function,
    extras: {
      type: Array,
      default: () => { return [] }
    }
  },
  components: {
    ComplementaryList
  },
  data () {
    return {
      hasComplementary: false,
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn'
    })
  },
  methods: {
    goTo (destination) {
      this.$router.push(destination)
    },
    removeProduct () {
      const self = this
      this.$store.dispatch('clinic/deleteShoppingCartItem', {
        cartItemId: this.cartProduct.id,
        cb: (response) => {
          self.updateProductCb()
        },
        cbError: (error) => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: error.response.data.error
            }
          })
        }
      })
      // deleteShoppingCartItem
    },
    editProduct (cartItem) {
      if (this.isLoggedIn) {
        this.openOrderDetail(cartItem)
      } else {
        this.openLogInModal(cartItem)
      }
    },
    openOrderDetail (cartItem) {
      const modalOptions = {
        componentId: 'CompleteOrderDetails',
        title: 'Revisar los detalles de la orden',
        laboratory: this.cartProduct.laboratory,
        productId: this.cartProduct.product_id,
        productName: this.cartProduct.product_name,
        productOfferId: this.cartProduct.product_offer_id,
        productOfferPrice: this.cartProduct.unit_price,
        patientName: this.cartProduct.first_name,
        patientLastName: this.cartProduct.last_name,
        odontologistName: this.cartProduct.odontologist_name,
        shoppingCartItem: this.cartProduct,
        activeExtras: this.extras,
        smallSize: false,
        isUpdate: true,
        isOrder: false,
        isReview: false,
        readOnly: false,
        updateCb: this.fetchCartItem
      }
      this.$store.commit({
        type: 'application/SHOW_MODAL',
        modalOptions: modalOptions
      })
    },
    openLogInModal (cartItem) {
      const self = this
      const modalOptions = {
        componentId: 'LoginRegister',
        title: 'Inicia sesión o registrate para seguir',
        smallSize: false,
        closeCb: () => {
          self.openOrderDetail(cartItem)
        }
      }
      this.$store.commit({
        type: 'application/SHOW_MODAL',
        modalOptions: modalOptions
      })
    },
    fetchCartItem () {
      this.updateProductCb()
    }
  },
  created () {
    if (!IsEmptyOrNull(this.cartProduct) && !IsEmptyOrNull(this.cartProduct.complementary_ids)) {
      this.hasComplementary = true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .cart-item-wrap{
    padding-bottom: 39px;
    border-bottom: 1px solid rgba(87,86,124,0.2);
    &:last-child{
      border-bottom: 0px;
    }
  }
  @media screen and (max-width: 768px) {
    .cart-item-wrap{
      padding: 24px;
      width: 100%;
      border-radius: 8px;
      background-color: #FFFFFF;
      box-shadow: 0 2px 24px 0 rgba(0,0,0,0.2);
      border: 0px;
      .mobile-action-wrap{
        overflow: hidden;
        .mobile-edit{
          float: left;
          width: 50%;
          .button{
            width: 100%;
          }
        }
        .mobile-remove{
          float: left;
          width: 50%;
          text-align: right;
          line-height: 46px;
        }
      }
    }
  }
</style>
